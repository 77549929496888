import { AxiosInstance, AxiosResponse } from 'axios';
import { CriteriaData } from '@models/CriteriaData';
import { HtmlPage, HtmlSearchResults } from '@models/HtmlPage';
import { ParsedUrlQuery } from 'querystring';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';
import { BotType } from '../../../components/modules/Elliot/ElliotMenu';
import { GPT_MODEL } from '../../../constants';

export class SearchApi {
  private static ROOT = '/search';

  private static searchClient: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Html)
    .setPrivate(true)
    .build();

  static fetchHtmls(
    criteria: CriteriaData[],
    offset: number,
    {
      // eslint-disable-next-line camelcase
      publication_date,
    }: ParsedUrlQuery,
  ): Promise<AxiosResponse<HtmlSearchResults>> {
    return this.searchClient.get<HtmlSearchResults>(`${this.ROOT}/`, {
      params: {
        criteria: JSON.stringify(criteria),
        // eslint-disable-next-line camelcase
        publication_date,
        offset,
      },
    });
  }

  static autocomplete(q: string): Promise<AxiosResponse<string[]>> {
    return this.searchClient.get<string[]>(`${this.ROOT}/autocomplete/`, {
      params: {
        q,
      },
    });
  }

  static elliotBooleanSearch(
    query: string,
    simple = true,
    threadId: string,
    assistantName: BotType,
    model: GPT_MODEL,
    doSort?: boolean,
  ): Promise<AxiosResponse<{ booleanQuery: string; htmlPages: HtmlPage[] }>> {
    return this.searchClient.get<{
      booleanQuery: string;
      htmlPages: HtmlPage[];
    }>(`${this.ROOT}/elliotBoolean/`, {
      params: {
        query,
        sort: doSort ? 'desc' : null,
        simple,
        threadId,
        assistantName: `${assistantName}${model === 'gpt-4o' ? '4o' : ''}`,
        model,
      },
    });
  }

  static elliotSearch(
    query: string,
    affiliation: string,
    affiliation2: string,
    threadId: string,
    assistantName: BotType,
    model: GPT_MODEL,
    doSort?: boolean,
  ): Promise<AxiosResponse<{ booleanQuery: string; htmlPages: HtmlPage[] }>> {
    return this.searchClient.get<{
      booleanQuery: string;
      htmlPages: HtmlPage[];
    }>(`${this.ROOT}/elliot/`, {
      params: {
        query,
        affiliation,
        affiliation2,
        sort: doSort ? 'desc' : null,
        threadId,
        assistantName: `${assistantName}${model === 'gpt-4o' ? '4o' : ''}`,
      },
    });
  }

  static getHtmlPage(id: string): Promise<AxiosResponse<HtmlPage>> {
    return this.searchClient.get<HtmlPage>(`${this.ROOT}/${id}/`);
  }
}
