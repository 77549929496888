import Router from 'next/router';
import { format } from 'date-fns';
import { CriteriaData } from '@models/CriteriaData';
import IAffiliationSummary from '@models/IAffiliationSummary';
import { StatsApi as OrganizationsStatsApi } from '@api/organizations/stats';
import ChatGptMessage from '@models/ChatGptMessage';
import { Filter } from '../components/search/filtering/Filter';
// eslint-disable-next-line import/no-cycle
import { Api } from '../components/templates/Graph/SearchDialog/ApiToggle';

/**
 * removed and optionally replaces html tags
 * @param str text from which html tags should be removed.
 * @param replacementString an optional string to replace html tags with
 */
export function removeHtmlTags(str?: string, replacementString?: string) {
  return str?.replace(/<[^>]+>/g, replacementString || '');
}

/**
 * replaces a string at a specific index
 * @param str string to process
 * @param index the index at which the replacement happens
 * @param replacement the string that is to be inserted at the given index
 * @param charNumToReplace the numbers of characters that ought to be removed from the appending substr
 */
function replaceAt(
  str: string,
  index: number,
  replacement: string,
  charNumToReplace?: number,
): string {
  return (
    str.substr(0, index) +
    replacement +
    str.substr(charNumToReplace || index + 1)
  );
}

/**
 * given a string returns a capitalized version of the string.
 * @param str text to process.
 * @param ignoreBHighlights determines if <b/> tags are to be ignored
 */
export function capitalizeFirstLetter(
  str?: string,
  ignoreBHighlights?: boolean,
): string {
  function getSlicedCharAt(chartAtIndex: number, sliceIndex: number) {
    return str?.charAt(chartAtIndex).toUpperCase() + str?.slice(sliceIndex);
  }

  if (ignoreBHighlights) {
    const searchTerm = '<b/>';
    const indexOfFirst = str.indexOf(searchTerm);
    return indexOfFirst !== 0
      ? getSlicedCharAt(0, 1)
      : replaceAt(str, 4, str?.charAt(4).toUpperCase(), 5);
  }

  return getSlicedCharAt(0, 1);
}

export function titleCase(str?: string): string {
  const splitStr = str?.toLowerCase().split(' ');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr?.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr?.join(' ');
}

/**
 * given a string returns a capitalized combination of the first letters of the first two words in the string.
 * @param text text to process.
 */
export function getTextInitials(text?: string): string {
  const cleansedText = removeHtmlTags(text);

  if (text === undefined) {
    return '';
  }

  return (
    cleansedText?.charAt(0) +
    (cleansedText?.indexOf(' ') + 1 > 0
      ? cleansedText?.substr(cleansedText?.indexOf(' ') + 1).charAt(0)
      : '')
  ).toUpperCase();
}

/**
 * mutates @param text by adding <b>searchText</b> to any matched @param searchText
 * @param text string to process
 * @param searchText string to match
 * @param color highlight color
 */
export function getTextWithHighlights(
  text = '',
  searchText?: string,
  color?: string,
): string {
  if (!searchText) {
    return text;
  }

  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (strReplace, strWith) {
    // See http://stackoverflow.com/a/3561711/556609
    const esc = strReplace.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
  };

  return text?.replaceAll(
    searchText,
    `<b style="color: ${color}" data-testid="highlightedText">$&</b>`,
  );
}

/**
 * returns an Object key given a field value of the key.
 * @param object Object to which the key belongs.
 * @param fieldValue a field value of the key that to be returned.
 */
export function findKeyFromObjectField(
  object: Object,
  fieldValue: string,
): any {
  return Object.keys(object).find((key) => object[key] === fieldValue);
}

/**
 * returns an Enum key given a certain enum value.
 * @param myEnum Enum type to which the key belongs.
 * @param enumValue an enum value that defines the returned key.
 */
export function getEnumKeyByEnumValue(
  myEnum: any,
  enumValue: number | string,
): string {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
}

/**
 * routes to a specified Filter
 * @param filterValue a value of a query param
 * @param router a next.js Router
 * @param filter a Filter type
 * @param keepRouterContext determines whether to keep Router's configuration intact
 * @returns booleanPromise returns Promise<boolean> that is returned by router.push function
 */
export const routeToFilter = (
  filterValue: any,
  router: typeof Router,
  filter: Filter,
  keepRouterContext: boolean,
): Promise<boolean> => {
  // eslint-disable-next-line no-param-reassign
  delete router.query.p;

  if (!filterValue) {
    // eslint-disable-next-line no-param-reassign
    delete router.query[filter.queryName];
    return router.push(router);
  }
  if (keepRouterContext) {
    /* // eslint-disable-next-line no-param-reassign
    delete router.query.or;
    // eslint-disable-next-line no-param-reassign
    delete router.query.and; */
    return router.push({
      pathname: filter.address ? filter.address : router.pathname,
      query: { ...router.query, [filter.queryName]: filterValue },
    });
  }
  return router.push(`${filter.address}?${filter.queryName}=${filterValue}`);
};

/**
 * removes all whitespace from a string using regex
 * @param str string to remove white space from
 */
export function removeAllWhiteSpace(str: string) {
  return str.replace(/\s/g, '');
}

export function getFloorPlusRoundedReminder(
  num: number,
  divider: number,
): number {
  if (num === 1) {
    return num;
  }

  return Math.floor(num / divider) + (divider % num !== 0 ? 1 : 0);
}

export function numberWithCommas(x?: number): string {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function removeWhiteSpace(str: string): string {
  return str.replace(/ /g, '');
}

export function makeEmptyArrayIfNull(object) {
  return !object ? [] : object;
}

export function formatDate(
  date1: string | Date,
  pattern = 'EEEE, MMM d yyyy',
): string {
  const date = new Date(date1);

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Date provides month index; not month number
  const day = date.getUTCDate();

  try {
    return date1 ? format(new Date(`${month}-${day}-${year}`), pattern) : '';
  } catch (e) {
    return '';
  }
}

export function hexToRGB(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

export function addCommasToNumber(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getIconForApi = (api: Api): string => {
  switch (api) {
    case 'affiliations':
      return 'fa6-solid:building';
    case 'people':
      return 'ic:baseline-people-alt';
    case 'tech':
      return 'fa6-solid:microchip';
    default:
      return 'fa6-solid:building';
  }
};

export function rewriteRouteWithNewCriteria(
  router: typeof Router,
  criteria: CriteriaData,
  address?: string,
) {
  router.push({
    pathname: address || router.pathname,
    query: {
      ...router.query,
      criteria: JSON.stringify([
        ...JSON.parse(router.query.criteria as string),
        {
          ...criteria,
          operator: criteria.operator || 'AND',
        },
      ]),
    },
  });
}

export async function getAffiliationChatBotData(
  affiliationSummary: IAffiliationSummary,
): Promise<string> {
  const publications = await OrganizationsStatsApi?.searchPublications(
    affiliationSummary?.literal_name,
    '',
    1,
    30,
    'asc',
  );

  const jsonPublications = Array.from(
    new Set(
      publications.data.data
        .flatMap((d) => ({ ...d.source, id: d.id }))
        .flatMap((publication) => publication?.affiliations),
    ),
  );

  return `Organization name: ${
    affiliationSummary.literal_name
  }, keywords: ${affiliationSummary.topKeywords?.slice(
    0,
    20,
  )}, affiliations: ${JSON.stringify(
    jsonPublications.slice(0, 30),
    // eslint-disable-next-line no-underscore-dangle
  )}, authors: ${JSON.stringify(affiliationSummary.author_)}`;
}

export function processPrompts(messages: ChatGptMessage[]) {
  const prompts = [];

  const preParse = (json: string) =>
    json.replace('```json', '').replace('```', '').replace('``` ', '');

  for (let i = 0; i < messages.length; i += 2) {
    if (messages[i + 1]?.metadata?.type === 'boolean') {
      console.log(
        messages[i - 1]?.content[0]?.text?.value?.includes(
          'Summarize (In short) research from China',
        )
          ? 'China'
          : 'Not China',
      );

      prompts.push({
        id: messages[i + 1].id,
        prompt: {
          content: messages[i - 2]?.content[0]?.text.value,
          role: messages[i].role,
          action: 'Elliot',
          context: 'web',
          maxTokens: '2000',
        },
        isFetched: true,
        booleanQuery: JSON.parse(preParse(messages[i]?.content[0]?.text.value))
          .booleanQuery,
        bot: 'Boolean',
        documents: messages[i - 1]?.content[0]?.text?.value?.includes(
          'Summarize (In short) research from China',
        )
          ? JSON.parse(
              preParse(
                messages[i - 1]?.content[0]?.text?.value?.replace(
                  'Summarize (In short) research from China, detailing the subjects studied and the organizations involved. The summary should provide an overview of the research themes, the methodologies employed, and the key outcomes. Mention the contributing institutions, emphasizing the broader implications of the findings across various fields: ',
                  '',
                ),
              ),
            )
          : JSON.parse(
              preParse(
                messages[i - 1]?.content[0]?.text?.value?.replace(
                  'Summarize this research, detailing the subjects studied and the organizations involved. The summary should provide an overview of the research themes, the methodologies employed, and the key outcomes. Include the titles of the research papers, and mention the contributing individuals and institutions, emphasizing the broader implications of the findings across various fields: ',
                  '',
                ),
              ),
            ),
        query: JSON.parse(preParse(messages[i]?.content[0]?.text.value)).query,
        isSimplifiedQuery: messages[i + 1].metadata?.simple === 'true',
        assistantName: messages[i + 1].metadata?.assistantName,
        dataset:
          messages[i + 1]?.metadata?.dataset === 'OpenAlex'
            ? 'OpenAlex'
            : 'Web',
      });
    } else if (
      !messages[i + 1]?.content[0].text.value.includes(
        'Summarize (In short) research from China',
      ) &&
      !messages[i + 1]?.content[0].text.value.includes(
        'Summarize this research',
      )
    ) {
      prompts.push({
        id: messages[i + 1].id,
        prompt: {
          content: messages[i]?.content[0]?.text.value,
          role: messages[i].role,
          action: 'Elliot',
          context: 'web',
          maxTokens: '2000',
        },
        isFetched: true,
        isSimplifiedQuery: true,
        query: messages[i + 1]?.content[0]?.text.value,
        dataset:
          messages[i + 1]?.metadata?.dataset === 'OpenAlex'
            ? 'OpenAlex'
            : 'Web',
      });
    }
  }

  return prompts.reverse();
}
